import qs from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { routes } from '../../../constants';
import { Breadcrumbs } from '../../controls/breadcrumbs/Breadcrumbs';
import { BreadcrumbsItem } from '../../controls/breadcrumbs/BreadcrumbsItem';
import IconSVG from '../../../styles/svg-icons';
import { newsActions } from '../../../actions/news.actions';
import { AppState } from '../../../types/state/AppState';
import { Preloader } from '../../common';
import { NewsNavigation } from '../../../types/news/News';
import { NewsCategory, newsCategoryTitles } from '../../../types/news/enums/NewsCategory';
import { NewsProvider, newsProviderLogos, newsProviderTitles } from '../../../types/news/enums/NewsProvider';
import { useQuery } from '../../../effects/useQuery';
import { TimeLabel } from '../news-list/TimeLabel';
import { NewsLabel, newsLabelTitles } from '../../../types/news/enums/NewsLabel';
import { ImSubscriptionActionBlocker } from '../../amrPipeline/subscription/ImSubscriptionActionBlocker';
import classNames from 'classnames';
import { EditNewsTabType } from '../../../types/news/enums/EditNewsTabType';
import { Confirm } from '../../alerts';
import { canEditNews } from '../../../utils/news.utils';
import { NewsAccessType } from '../../../types/amr-pipeline/enums/NewsAccessType';
import DownloadDocument from '../../amrPipeline/common/documents/DownloadDocument';
import { FileIcon } from '../../controls';
import { AmrFile } from '../../../types/amr-pipeline/models/AmrFile';

interface NewsPageRouteParams {
    newsReferenceName: string;
}

export interface NewsPageRouteQuery {
    search?: string;
    category?: NewsCategory;
}

export const NewsPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { newsReferenceName } = useParams<NewsPageRouteParams>();
    const { search, category } = useQuery<NewsPageRouteQuery>();

    const [showDeletePopup, setShowDeletePopup] = useState(false);

    const canEdit = canEditNews();

    const { currentNews, isNewsLoading } = useSelector((state: AppState) => state.news);

    const getQueryParams = useCallback(() => ({
        searchTerm: search,
        categories: category ? [category] : undefined
    }), [search, category]);

    useEffect(() => {
        dispatch(newsActions.fetchNewsDetails(newsReferenceName, getQueryParams()));

        return () => {
            dispatch(newsActions.reset());
        };
    }, [dispatch, getQueryParams, newsReferenceName]);

    useEffect(() => {
        if (currentNews?.title) {
            document.title = `${currentNews.title} - KTX ATS Platform`;
        }
    }, [currentNews?.title]);

    const navigate = (newsReferenceName: string) => () => {
        const query: NewsPageRouteQuery = { search, category };

        const queryString = qs.stringify(query);

        history.push(`${routes.newsUrl(newsReferenceName)}?${queryString}`);
    };

    const handleDeleteNews = () => {
        dispatch(newsActions.deleteSingleNews(currentNews!.referenceName));
    };

    const handleDownloadFile = (file: AmrFile) => {
        dispatch(newsActions.downloadDocument(currentNews!.referenceName, file.referenceName, file.name));
        dispatch(newsActions.logUserActivity(NewsAccessType.newsDocument));
    };

    const renderFiles = () => {
        const files = currentNews?.newsDocuments;

        if (!files?.length) {
            return null;
        }

        return (
            <div className="documents-wrap">
                {files.map((file) => (
                    <div className="document-item">
                        <DownloadDocument
                            onClick={() => handleDownloadFile(file)}
                            document={file}
                            secondary
                        >
                            <FileIcon filename={file.name} />
                            <span>{file.name}</span>
                        </DownloadDocument>
                    </div>
                ))}
            </div>
        );
    };

    const renderNews = (news: NewsNavigation) => {
        return (
            <div className="tabs-content container-flex">
                <div className="tabs-data tabs-data-padding">
                    <div className="news-wrapper">
                        <Breadcrumbs>
                            <BreadcrumbsItem
                                route={routes.newsUrl()}
                                text="K-Watch News"
                            />
                        </Breadcrumbs>
                        <div className="flex-row news-controls">
                            {news.category !== NewsCategory.None && (
                                <div className="news-controls-item">
                                    <div className={classNames("label-status label-status-news-category", `label-status-${newsCategoryTitles[news.category].toLowerCase().replace(' ', '-')}`)}>
                                        {newsCategoryTitles[news.category]}
                                    </div>
                                </div>
                            )}
                            {news.provider && news.provider !== NewsProvider.None && (
                                <div className="news-controls-item">
                                    <div className="news-provider-clo">
                                        <div className={classNames({
                                            "news-provider-london-stock-exchange-group": NewsProvider.LondonStockExchangeGroup === news.provider,
                                            "news-provider-clo-img": NewsProvider.CLOResearchGroup === news.provider
                                        })}>
                                            <img src={newsProviderLogos[news.provider]} alt={newsProviderTitles[news.provider]} />
                                        </div>
                                        <span className="news-provider-clo-text">Provided by {newsProviderTitles[news.provider]}</span>
                                    </div>
                                </div>
                            )}
                            {news.label !== NewsLabel.None &&
                                <div className="news-controls-item">
                                    <div className="news-label text-sm">
                                        {newsLabelTitles[news.label]}
                                    </div>
                                </div>
                            }
                            {canEdit && (
                                <div className="flex-row news-controls-edit">
                                    <div className="news-controls-item news-controls-item-edit">
                                        <a
                                            className="btn-link"
                                            href={routes.manageNewsUrl(news.referenceName, EditNewsTabType.edit)}
                                        >
                                            <IconSVG name="edit-pencil" width={16} height={16} />
                                        </a>
                                    </div>
                                    <div className="news-controls-item news-controls-item-edit">
                                        <button
                                            className="btn-link btn-danger"
                                            onClick={() => setShowDeletePopup(true)}
                                        >
                                            <IconSVG name="basket" width={16} height={16} />
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                        <h1 className="news-title">{news.title}</h1>
                        <div className="flex-row news-item-additional">
                            <div className="news-item-additional-item">
                                <TimeLabel publishingDate={news.newsDateTime} />
                            </div>

                            {!!news.externalSourceLink &&
                                <div className="news-item-additional-item">
                                    <a
                                        onClick={() => dispatch(newsActions.logUserActivity(NewsAccessType.externalSource))}
                                        href={news.externalSourceLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        External Source
                                    </a>
                                </div>
                            }
                            {!!news.transactionLink &&
                                <div className="news-item-additional-item">
                                    <ImSubscriptionActionBlocker>
                                        {blocked =>
                                            <a
                                                onClick={() => dispatch(newsActions.logUserActivity(NewsAccessType.dealDetails))}
                                                href={news.transactionLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className={classNames({ 'disabled': blocked })}
                                            >
                                                Deal Details
                                            </a>
                                        }
                                    </ImSubscriptionActionBlocker>
                                </div>
                            }
                            {!!news.cloManagerReferenceName &&
                                <div className="news-item-additional-item">
                                    <a
                                        onClick={() => dispatch(newsActions.logUserActivity(NewsAccessType.managerProfile))}
                                        href={routes.manageCloManagerUrl(news.cloManagerReferenceName)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Manager Profile
                                    </a>
                                </div>
                            }
                            {!!news.arrangerReferenceName &&
                                <div className="news-item-additional-item">
                                    <ImSubscriptionActionBlocker>
                                        {blocked =>
                                            <a
                                                onClick={() =>
                                                    dispatch(newsActions.logUserActivity(NewsAccessType.arranger))
                                                }
                                                className={classNames({ disabled: blocked })}
                                                href={routes.manageBanksUrl(news.arrangerReferenceName)}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Arranger
                                            </a>
                                        }
                                    </ImSubscriptionActionBlocker>
                                </div>
                            }
                        </div>
                        <div className="news-content" dangerouslySetInnerHTML={{ __html: news.description }}>
                        </div>
                        {renderFiles()}
                        <div className="flex-row news-bottom-controls">
                            <div className="news-bottom-controls-item news-bottom-controls-item-left container-flex align-items-flex-start">
                                {news.previousReferenceName && (
                                    <>
                                        <button className="btn-link" onClick={navigate(news.previousReferenceName)}>
                                            <IconSVG name="icon-expand" width="16" height="16" />
                                            Previous Article
                                        </button>
                                        <button className="btn-link regular text-left text-sm news-bottom-controls-description" onClick={navigate(news.previousReferenceName)}>
                                            {news.previousTitle}
                                        </button>
                                    </>
                                )}
                            </div>
                            <div className="news-bottom-controls-item news-bottom-controls-item-right container-flex align-items-flex-end">
                                {news.nextReferenceName && (
                                    <>
                                        <button className="btn-link" onClick={navigate(news.nextReferenceName)}>
                                            Next Article
                                            <IconSVG name="icon-expand" width="16" height="16" />
                                        </button>
                                        <button className="btn-link regular text-right text-sm news-bottom-controls-description" onClick={navigate(news.nextReferenceName)}>
                                            {news.nextTitle}
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <Preloader inProgress={isNewsLoading}>{currentNews && renderNews(currentNews)}</Preloader>

            {showDeletePopup && currentNews && (
                <Confirm
                    title="Remove News"
                    text={
                        <>
                            Are you sure you want to remove <b>{currentNews.title}</b> news?
                        </>
                    }
                    onConfirm={handleDeleteNews}
                    onCancel={() => setShowDeletePopup(false)}
                />
            )}
        </>
    );
};
